import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';
import { CommonMap } from 'client/tracking/maps/common';

export const map = {
  make_index_model_select: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_INVENTORY_SELECT,
    TrackingConstant.MODEL_SELECT
  ),
  make_index_view_makes: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_MAKES
  ),
  make_index_history_expand: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RESET_NAVIGATION,
    TrackingConstant.NAVIGATE_EXPAND,
    {
      value: 'read_more',
    }
  ),
  make_index_history_collapse: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RESET_NAVIGATION,
    TrackingConstant.NAVIGATE_COLLAPSE,
    {
      value: 'read_less',
    }
  ),
  zip_code_entry: TrackingEvent.actionProgress(TrackingConstant.ACTION_MMZ_SELECT, TrackingConstant.ZIP_CODE_ENTRY),
  submit_mmz: TrackingEvent.actionEnd(TrackingConstant.ACTION_MMZ_SELECT, TrackingConstant.SUBMIT_MMZ),
};

export const TrackingMap = {
  ...CommonMap,
  ...map,
};
