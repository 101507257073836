import { SCHEMA_URL } from 'site-modules/shared/pages/seo';

// https://schema.org/ListItem
function getListItem({ name, position, url }) {
  return {
    '@type': 'ListItem',
    position,
    name,
    url,
  };
}

/**
 * Get ItemList Schema
 * https://schema.org/ItemList
 * To avoid errors pay attention to the type of a page you use the schema on (summary or all-in-one)
 * More info: https://developers.google.com/search/docs/data-types/carousel#single-all-in-one-page-list
 * @param {Object[]} items Array of objects { name: string, position: string|number, url: string }
 * @param {String} name Title of the list
 * @param {String} pageUrl URL of the page that contains the list
 * @param {String} orderType See https://schema.org/ItemListOrderType
 * @returns {Object}
 */

export function getListItemSchema({ items, name, pageUrl, orderType = 'https://schema.org/ItemListOrderDescending' }) {
  if (!items || !items.length) return null;

  return {
    '@context': SCHEMA_URL,
    '@type': 'ItemList',
    itemListOrder: orderType,
    name,
    numberOfItems: items.length,
    url: pageUrl,
    itemListElement: items.map(getListItem),
  };
}
