import PropTypes from 'prop-types';
import { get } from 'lodash';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';

const SimilarVehicleCrossShop = PropTypes.shape({
  make: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  model: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  year: PropTypes.number,
  startPrice: PropTypes.number,
  consumerRating: PropTypes.shape({
    averageRating: PropTypes.number,
    reviewsCount: PropTypes.number,
  }),
});

const VehicleSubmodel = PropTypes.shape({
  makeName: PropTypes.string,
  modelName: PropTypes.string,
  modelSlug: PropTypes.string,
  year: PropTypes.number,
  vehicleType: PropTypes.string,
  publicationStates: PropTypes.arrayOf(PropTypes.string),
  submodelId: PropTypes.number,
  submodelName: PropTypes.string,
  submodelSlug: PropTypes.string,
  isDefaultSubmodel: PropTypes.bool,
  isDefaultYear: PropTypes.bool,
});

export const CoreResearchEntities = {
  SimilarVehicleCrossShop,
  VehicleSubmodel,
};

export function buildSimilarVehiclesCrossShopPath(modelLinkCode, pageSize = 3) {
  return !!modelLinkCode && `modelLinkCodes["${modelLinkCode}"].similarVehicles.pageSize["${pageSize}"]`;
}

export function buildSubmodelsListPath(makeSlug) {
  return `makes["${makeSlug}"].submodelsList`;
}

export function getCoreStyleIdPath() {
  return 'corePage.styleId';
}

export const CoreResearchModel = createModelSegment('coreResearch', [
  /**
   * @see buildSimilarVehiclesCrossShopPath()
   * @returns {Array<SimilarVehicleCrossShop>}
   */
  {
    path: 'modelLinkCodes["{modelLinkCode}"].similarVehicles.pageSize["{pageSize}"]',
    resolve({ modelLinkCode, pageSize }, context) {
      return withMetrics(EdmundsAPI, context)
        .fetchJson(`/coreresearch/v1/similarvehicles/${modelLinkCode}/new/?pageSize=${pageSize}&pageNum=1`)
        .then(response => get(response, 'results'))
        .catch(() => []);
    },
  },
  /**
   * @see buildSubmodelsListPath
   * @returns {Array<VehicleSubmodel>}
   */
  {
    path: 'makes["{makeSlug}"].submodelsList',
    resolve({ makeSlug }, context) {
      return withMetrics(EdmundsAPI, context)
        .fetchJson(`/coreresearch/v1/modelyears/${makeSlug}/`)
        .then(response => get(response, 'results'))
        .catch(() => []);
    },
  },
]);
