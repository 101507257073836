import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import Button from 'reactstrap/lib/Button';
import { CoreResearchEntities } from 'client/data/models/core-research';
import { VEHICLE_TYPE_FILTERS } from 'site-modules/make/constants/make';
import { isMatchingFilter, separatePubStates } from 'site-modules/make/utils/make';

import './filters-list.scss';

export function FiltersList({ activeFilter, submodelsList, setActiveFilter, isConnect }) {
  function onFilterClick(event) {
    setActiveFilter(get(event, 'target.dataset.filter'));
  }

  const filterSubmodels = isConnect ? separatePubStates(submodelsList).newUsedList : submodelsList;
  const typeFilters = VEHICLE_TYPE_FILTERS.map(typeFilter => ({
    filterName: typeFilter,
    vehicleCount: filterSubmodels.filter(({ vehicleType }) => isMatchingFilter(vehicleType, typeFilter)).length,
  }));

  return (
    <ul className="filters-list list-unstyled mb-1_5">
      {typeFilters.map(
        ({ filterName, vehicleCount }) =>
          !!vehicleCount && (
            <li className="d-inline-block px-0_5 px-sm-1" key={`filter-${filterName}`}>
              <Button
                color="link"
                size="lg"
                className={classnames('filter py-0_5 px-0', {
                  'text-primary-darker filter-active': filterName === activeFilter,
                })}
                data-filter={filterName}
                onClick={onFilterClick}
                data-tracking-id="make_index_model_select"
              >
                {filterName} ({vehicleCount})
              </Button>
            </li>
          )
      )}
    </ul>
  );
}

FiltersList.propTypes = {
  activeFilter: PropTypes.oneOf(VEHICLE_TYPE_FILTERS).isRequired,
  setActiveFilter: PropTypes.func.isRequired,
  submodelsList: PropTypes.arrayOf(CoreResearchEntities.VehicleSubmodel).isRequired,
  isConnect: PropTypes.bool,
};

FiltersList.defaultProps = {
  isConnect: false,
};
