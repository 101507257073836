export const TYPE_FILTER = {
  ALL: 'All',
  CAR: 'Car',
  SUV: 'SUV',
  TRUCK: 'Truck',
  MINIVAN_VAN: 'Minivan/Van',
};

export const VEHICLE_TYPE_FILTERS = [
  TYPE_FILTER.ALL,
  TYPE_FILTER.CAR,
  TYPE_FILTER.SUV,
  TYPE_FILTER.TRUCK,
  TYPE_FILTER.MINIVAN_VAN,
];
