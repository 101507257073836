import { groupBy, maxBy, map } from 'lodash';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { getCoreUrl } from 'site-modules/shared/utils/core-link-constructor';
import { TYPE_FILTER } from 'site-modules/make/constants/make';
import { PUB_STATES } from 'client/constants/pub-states';
import { getHomeBreadcrumb } from 'client/site-modules/shared/components/breadcrumbs/helper';

export function isMatchingFilter(type, filter) {
  return filter === TYPE_FILTER.ALL || (filter && type && filter.toLowerCase().includes(type.toLowerCase()));
}

export function getMakeHistoryContentPath(make) {
  return `${make}/history`;
}

export function separatePubStates(submodelsList) {
  const { true: preProdList, false: newUsedList } = groupBy(
    submodelsList,
    ({ publicationStates }) => publicationStates[0] === PUB_STATES.PRE_PROD
  );

  return { preProdList, newUsedList };
}

export function filterSubmodels(submodelsList) {
  const { preProdList = [], newUsedList = [] } = separatePubStates(submodelsList);

  const newUsedFiltered = map(
    groupBy(newUsedList, 'submodelName'),
    sameSubmodels => sameSubmodels.find(({ isDefaultYear }) => isDefaultYear) || maxBy(sameSubmodels, 'year')
  );

  return [...newUsedFiltered, ...preProdList];
}

export function buildCoreLinkFromSubmodel(submodel) {
  const { year, makeName, modelName, submodelSlug, isDefaultSubmodel, isDefaultYear } = submodel;
  const make = makeNiceName(makeName);
  const model = makeNiceName(modelName);
  return getCoreUrl({
    makeSlug: make,
    modelSlug: model,
    submodelSlug: isDefaultSubmodel ? null : submodelSlug,
    year: isDefaultYear && isDefaultSubmodel ? null : year,
  });
}

export function buildConnectLinkFromSubmodel(submodel) {
  const { year, makeName, modelName, isDefaultYear } = submodel;
  const make = makeNiceName(makeName);
  const model = makeNiceName(modelName);
  return `${getCoreUrl({
    makeSlug: make,
    modelSlug: model,
    year: isDefaultYear ? null : year,
  })}connect/`;
}

export function getMakeBreadcrumbs(makeName, isForSchema) {
  return [
    getHomeBreadcrumb(),
    { title: `${makeName} models`, href: isForSchema ? `/${makeNiceName(makeName)}/` : null },
  ];
}
