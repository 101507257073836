import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import CardImg from 'reactstrap/lib/CardImg';
import CardTitle from 'reactstrap/lib/CardTitle';
import { CoreResearchEntities } from 'client/data/models/core-research';
import { getDefaultStockPhotoBySize, getStaticImageUrl, SOURCES } from 'client/utils/image-helpers';
import { VEHICLE_TYPE_FILTERS } from 'site-modules/make/constants/make';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { Image } from 'site-modules/shared/components/image/image';
import { ArrowLink } from 'site-modules/shared/components/arrow-link/arrow-link';
import { NO_VEHICLE_GRAY_IMAGE_SEDAN } from 'site-modules/shared/constants/images';
import {
  isMatchingFilter,
  buildCoreLinkFromSubmodel,
  buildConnectLinkFromSubmodel,
} from 'site-modules/make/utils/make';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';
import { Link } from 'site-modules/shared/components/link/link';

export function ModelsList({ submodelsList, activeFilter, heading, isConnect }) {
  if (!submodelsList) {
    return null;
  }

  const hasVisibleVehicle = submodelsList.some(({ vehicleType }) => isMatchingFilter(vehicleType, activeFilter));

  return (
    <Fragment>
      {!!(hasVisibleVehicle && heading) && (
        <Fragment>
          <hr className="mt-0_5 mb-2" />
          <div className="heading-3 mb-1_5">{heading}</div>
        </Fragment>
      )}
      <Row tag="ul" className="mb-0 models-list list-unstyled">
        {submodelsList.map(submodel => {
          const { year, makeName, modelName, submodelName, submodelSlug, vehicleType } = submodel;
          const make = makeNiceName(makeName);
          const model = makeNiceName(modelName);
          const isVisible = isMatchingFilter(vehicleType, activeFilter);
          const coreUrl = isConnect ? buildConnectLinkFromSubmodel(submodel) : buildCoreLinkFromSubmodel(submodel);
          const displayVehicle = `${year} ${makeName} ${submodelName}`;

          return (
            <Col
              tag="li"
              xs={6}
              md={4}
              key={displayVehicle}
              className={classnames('mb-1', { 'd-none': !isVisible })}
              aria-hidden={!isVisible}
            >
              <Card className="model-card flex-column h-100">
                <Link
                  to={coreUrl}
                  className="w-100"
                  data-tracking-parent="new-photo_core_link-make_index"
                  data-tracking-id="view_content_models"
                  data-tracking-value={displayVehicle}
                >
                  <AspectRatioContainer aspectRatio="3:2">
                    <CardImg
                      tag={Image}
                      errorImage={getStaticImageUrl(NO_VEHICLE_GRAY_IMAGE_SEDAN)}
                      imageId={getDefaultStockPhotoBySize({ make, model, year, submodel: submodelSlug }, 300, 200)}
                      source={SOURCES.MEDIA}
                      alt={displayVehicle}
                      className="w-100"
                      top
                    />
                  </AspectRatioContainer>
                </Link>
                <CardBody className="p-1 h-100">
                  <CardTitle
                    className="heading-5 mb-0 text-primary-darker"
                    data-tracking-parent="new-text_core_link-make_index"
                  >
                    <ArrowLink to={coreUrl} data-tracking-id="view_content_models">
                      {displayVehicle}
                    </ArrowLink>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Fragment>
  );
}

ModelsList.propTypes = {
  activeFilter: PropTypes.oneOf(VEHICLE_TYPE_FILTERS).isRequired,
  submodelsList: PropTypes.arrayOf(CoreResearchEntities.VehicleSubmodel),
  heading: PropTypes.string,
  isConnect: PropTypes.bool,
};

ModelsList.defaultProps = {
  submodelsList: null,
  heading: null,
  isConnect: false,
};
